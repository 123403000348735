// React
import React from 'react';
import Helmet from 'react-helmet';

// Gatsby
import { Link, graphql } from 'gatsby';

// Third-party
import get from 'lodash/get';
import moment from 'moment';

// Local components
import Layout from '../components/layout';

class RunsIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title');
    const runs = get(this, 'props.data.allContentfulDragstripRun.edges');

    return (
      <Layout location={this.props.location}>
        <div style={{ background: '#fff', color: '#373f49' }}>
          <Helmet title="All Runs" />
          <div className="wrapper">
            <h1 className="section-headline">All Runs</h1>
            <div>
              Click on a run # to see the run details. Click on a track name to see track details.
            </div>
            <table style={{ width: '100%', marginTop: '8px' }}>
              <thead>
                <tr>
                  <th style={{ textAlign: 'left', width: '60px' }}>Run #</th>
                  <th style={{ textAlign: 'left', width: '190px' }}>Date/time</th>
                  <th style={{ textAlign: 'right', width: '80px' }}>RT</th>
                  <th style={{ textAlign: 'right', width: '85px' }}>60'</th>
                  <th style={{ textAlign: 'right', width: '85px' }}>1/8 ET</th>
                  <th style={{ textAlign: 'right', width: '85px' }}>1/8 MPH</th>
                  <th style={{ textAlign: 'right', width: '85px' }}>1/4 ET</th>
                  <th style={{ textAlign: 'right', width: '85px' }}>1/4 MPH</th>
                  <th style={{ textAlign: 'right', width: '75px' }}>DA</th>
                  <th style={{ textAlign: 'right' }}>Track</th>
                </tr>
              </thead>
              <tbody>
                {runs.map(({ node }) => {
                  return (
                    <tr key={node.slug}>
                      <td>
                        <Link to={`/run/${node.slug}`}>{node.round}</Link>
                      </td>
                      <td>{moment(node.dateTime).format('MM/DD/YYYY hh:mm:ss A')}</td>
                      <td style={{ textAlign: 'right' }}>{node.reactionTime.toFixed(4)}</td>
                      <td style={{ textAlign: 'right' }}>{node.sixtyFootET.toFixed(4)}</td>
                      <td style={{ textAlign: 'right' }}>{node.eighthMileET.toFixed(4)}</td>
                      <td style={{ textAlign: 'right' }}>{node.eighthMileMPH.toFixed(2)}</td>
                      <td style={{ textAlign: 'right' }}>
                        {node.quarterMileET ? node.quarterMileET.toFixed(4) : '-'}
                      </td>
                      <td style={{ textAlign: 'right' }}>
                        {node.quarterMileMPH ? node.quarterMileMPH.toFixed(2) : '-'}
                      </td>
                      <td style={{ textAlign: 'right' }}>{node.densityAltitude}</td>
                      <td style={{ textAlign: 'right' }}>
                        <Link to={`/track/${node.event.track.slug}`}>{node.event.track.name}</Link>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </Layout>
    );
  }
}

export default RunsIndex;

export const pageQuery = graphql`
  query RunsIndexQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulDragstripRun(sort: { fields: [dateTime], order: ASC }) {
      edges {
        node {
          slug
          round
          dateTime
          reactionTime
          sixtyFootET
          eighthMileET
          eighthMileMPH
          quarterMileET
          quarterMileMPH
          densityAltitude
          event {
            track {
              slug
              name
            }
          }
        }
      }
    }
  }
`;
